<template>
    <section>
<!--        <section class="section section_type_card">2</section>-->
<!--        <section class="section section_type_card">3</section>-->
<!--        <section class="section section_type_card">4</section>-->
    </section>
</template>

<script>
    export default {
        name: "Geoitems"
    }
</script>

<style scoped>

</style>